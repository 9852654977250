@import '../../../Variables';

:root {
    font-family: @fontFamily;
    --unit: 8px;
    --text-size: calc(var(--unit) * 2);
    font-size: var(--text);

    /* colors */

    --text: #000;
    --background: white;
    --border: #ddd;
    --code-background: #ddd;
    --border-light: #eee;
    --theme: #b62eb6;
    --theme-dark: #861286;
    --theme-light: #ffedff;
}

@media (prefers-color-scheme: dark) {
    :root {
        --text: #eee;
        --background: #222;
        --border: #777;
        --border-light: #555;
        --code-background: #171717;
        --theme: #861286;
        --theme-light: #331130;
        --theme-dark: #ffedff;
    }
}

.editor {
    // border: 1px solid var(--border);
    padding: var(--text-size);
    border-radius: var(--unit);
    box-sizing: border-box;

    li {
        margin-left: 35px;
    }
}

// TipTap ProseMirror

.ProseMirror.editor {
    min-height: 150px;
}

// Display a Placeholder only for the first line in an empty editor. (https://tiptap.dev/api/extensions/placeholder)
.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.ProseMirror hr {
    margin-bottom: 10px;
}

.editor-readonly {
    border-radius: var(--unit);
    box-sizing: border-box;

    li {
        margin-left: 35px;
    }
}



.editor:focus {
    outline: none;
    // box-shadow: 0 0 0 2px var(--background), 0 0 0 4px var(--theme);
}
.editor p {
    margin: 0;
}
.editor > :first-child {
    margin-top: 0;
}
.editor > *:not(:last-child) {
    margin-bottom: var(--text-size);
}
.editor > :last-child {
    margin-bottom: 0;
}
.editor blockquote {
    background-color: var(--theme-light);
    margin-left: 20px;
    padding: var(--text-size);
    border-left: 4px solid var(--theme);
}

button {
    cursor: pointer;
}

.menu-button {
    color: var(--text-color);
    background: none;
    border: 1px solid var(--border-light);
    padding: var(--unit);
    font-weight: 600;
    border-radius: 4px;
    margin-right: 4px;
    margin-bottom: 8px;
    width: 40px;
    height: 40px;
    padding: 0;
}
.menu-button.is-active {
    background: var(--theme-light);
    color: var(--theme-dark);
    border-color: var(--theme);
}

.bubble-menu {
    display: flex;
    background-color: var(--text);
    padding: 0.2rem;
    border-radius: 0.5rem;
}
.bubble-menu button {
    border: none;
    background: none;
    color: var(--background);
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.5rem;
    opacity: 0.6;
}
.bubble-menu button:hover,
.bubble-menu button.is-active {
    opacity: 1;
}

@primary-color: #663399;@highlight-color: #f5222d;@component-background: #fff;@processing-color: #eb615c;@label-color: #70757b;@background-color-light: #F4F4F8;@font-size-base: 14px;@table-font-size: 14px;@menu-item-font-size: 15px;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 6px;@checkbox-size: 18px;@checkbox-border-radius: 2px;@select-single-item-height-lg: 41px;@control-padding-horizontal: 12px;@input-height-lg: 41px;@avatar-size-lg: 80px;@avatar-size-base: 30px;@font-size-lg: 16px;@border-radius-base: 8px;@dropdown-edge-child-vertical-padding: 12px;@btn-primary-color: #fff;@btn-primary-shadow: 0 0 0 rgba(0, 0, 0, 0);@btn-height-lg: 41px;@btn-height-base: 38px;@btn-font-size-lg: 16px;@btn-font-weight: 500;@btn-shadow: 0 0 0 rgba(0, 0, 0, 0);@radio-size: 18px;@radio-top: 0;@comment-padding-base: 6px 0;